<template>
	<div class="mine page">
		<div class="page-bg" style=" background-size: 100% 100%;background-image: url(&quot;https://luxurylu.cc/IMG_0164.JPG&quot;);"></div>
		<div class="wrapper">
			<van-pull-refresh pulling-text="Sao chép nhanh quy trình thả xuống" loosing-text="Bản sao nhắc nhở của quá trình phát hành" loading-text="Đang tải bản sao nhắc quá trình" success-text="Làm mới bản sao lời nhắc thành công" v-model="isLoading" @refresh="onRefresh">
				<div class="header">
					<van-nav-bar class="nav-bar" style="    background: none;">
						<template #right>
							<van-icon name="setting-o" @click="showSetting()" color="#fff" />
						</template>
					</van-nav-bar>
					<div class="user-wrapper" @click="doLogin()">
						<van-image round class="user_img" :src="this.userInfo.header_img">
					<!-- <van-image round class="user_img" src="https://remote.beautyloves.net/avatar/6.png"> -->
							
               <template v-slot:loading>
                 <van-loading type="spinner"/>
               </template>
             </van-image>
						<div class="login-content" style="    margin-bottom: 20px;">
							<p class="login-btn">
								{{this.userInfo.username}}
							</p>
							<p class="login-btn" style="       margin-left: 20px; color: rgb(200, 157, 96);
    font-weight: bold;">
								<img style="width: 40px;height: 40px;    position: relative;
    top: 10px;
    margin-right: 2px;" :src="'img/v' + userInfo.level + '.png'" /> 
								<span style="color: red;">{{userInfo.levelname}}</span>
							</p>
							<!-- <p class="login-label">{{this.userInfo.ip}}</p> -->
						</div>
					</div>
				</div>
				<div class="content">
					<div class="finance">
						<div class="finance-item" @click="toService()">
							<van-icon class="icon" style="" name="peer-pay" />
							<span class="text">{{$t("my.recharge")}}</span>
						</div>
						<div class="line"></div>
						<div class="finance-item" @click="doWithdrawal()">
							<van-icon class="icon" name="idcard" />
							<span class="text">{{$t("my.withdraw")}}</span>
						</div>
					</div>
					<div v-if="this.userInfo.money" class="wallet">
						<div class="part-1 van-hairline--bottom">
							<p class="flex-1 font-28 font-primary-color">{{$t("my.my_balance")}}</p>
							<!-- <span class="font-28 font-gray">{{$t("my.detail")}}</span>
							<van-icon class="font-gray" style="font-size: 28px" name="arrow" /> -->
						</div>

						<div class="part-2">
							<p class="balance van-ellipsis">{{parseInt(this.userInfo.money).toLocaleString()}}</p>
							<span class="font-28 font-gray">{{$t("my.sl")}}</span>
							<div class="refresh-btn" @click="refresh()"><van-icon name="replay" /></div>
						</div>
						<!-- <div class="part-2">
							<p class="balance van-ellipsis">{{parseInt(this.userInfo.score).toLocaleString()}}</p>
							<span class="font-28 font-gray">{{$t("my.zypf")}}</span>
							<div class="refresh-btn" @click="refresh()"><van-icon name="replay" /></div>
						</div> -->
						

					</div>
					<div :style="{ marginTop: menu_top +'px'}" class="menu">
						<div class="menu-item" @click="$router.push({path:'/Personalreport'});">
							<van-image class="menu-item-icon" src="img/mine/baobiao.svg">
								<template v-slot:loading>
									<van-loading type="spinner" />
								</template>
							</van-image>
							<span class="menu-item-label">{{$t("my.my_statement")}}</span>
						</div>
						<div class="menu-item" @click="exit()">
							<van-image class="menu-item-icon" src="img/mine/mingxi.svg">
								<template v-slot:loading>
									<van-loading type="spinner" />
								</template>
							</van-image>
							<span class="menu-item-label">{{$t("my.account_detail")}}</span>
						</div>
						<div class="menu-item" @click="$router.push({path:'/GameRecord'});">
							<van-image class="menu-item-icon" src="img/mine/youxi.svg">
								<template v-slot:loading>
									<van-loading type="spinner" />
								</template>
							</van-image>
							<span class="menu-item-label">{{$t("my.task_record")}}</span>
						</div>
						<div class="menu-item" @click="$router.push({path:'/Infomation'});">
							<van-image class="menu-item-icon" src="img/mine/user.svg">
								<template v-slot:loading>
									<van-loading type="spinner" />
								</template>
							</van-image>
							<span class="menu-item-label">{{$t("my.personal_center")}}</span>
						</div>
						<div class="menu-item" @click="toNotice()">
							<van-image class="menu-item-icon" src="img/mine/gonggao.svg">
								<template v-slot:loading>
									<van-loading type="spinner" />
								</template>
							</van-image>
							<span class="menu-item-label">{{$t("my.information_announcement")}}</span>
						</div>
						<div class="menu-item" @click="toService()">
							<van-image class="menu-item-icon" src="img/mine/kefu_1.svg">
								<template v-slot:loading>
									<van-loading type="spinner" />
								</template>
							</van-image>
							<span class="menu-item-label">{{$t("my.online_service")}}</span>
						</div>
					</div>
				</div>
			</van-pull-refresh>
		</div>
	</div>

</template>

<script>
	export default {
		data() {
			return {
				userInfo: {},
				menu_top: 40,
				isLoading: false,
			};
		},
		methods: {
			refresh() {
				this.isLoading = true;
				setTimeout(() => {
					this.isLoading = false;
					if (localStorage.getItem('token')) {
						this.$toast(this.$t("reservation.refresh"));
					} else {
						this.$router.push({
							path: '/Login'
						})
					}
				}, 500);
			},
			exit() {
				this.$toast(this.$t("my.finish_task"));
			},
			showSetting() {
				if (localStorage.getItem('token')) {
					this.$router.push({
						path: '/Setting'
					})
				} else {
					this.$router.push({
						path: '/Login'
					})
				}
			},
			toNotice() {
				if (localStorage.getItem('token')) {
					this.$router.push({
						path: '/Notice'
					})
				} else {
					this.$router.push({
						path: '/Login'
					})
				}
			},
			onRefresh() {
				setTimeout(() => {
					this.isLoading = false;
					if (localStorage.getItem('token')) {
						this.getUserInfo();
						this.$toast(this.$t("reservation.refresh"));
					} else {
						this.$router.push({
							path: '/Login'
						})
					}
				}, 500);
			},
			doLogin() {
				if (localStorage.getItem('token')) {
					this.$router.push({
						path: '/Infomation'
					});
				} else {
					this.$router.push({
						path: '/Login'
					})
				}
			},
			doPay() {
				this.$router.push({
					name: 'Recharge',
					params: {
						'balance': this.userInfo.money
					}
				})
			},
			doWithdrawal() {
				this.$http({
					method: 'get',
					url: 'user_get_bank'
				}).then(res => {
					if (res.data.is_bank) {
						this.$router.push("withdraw");
					} else {
						this.$router.push("BindCard");
						// this.$router.push("Setbank");
						// this.$toast.fail(this.$t("setting.set_bank"));
					}
				})
			},
			toService() {
				if (this.$store.getters.getBaseInfo.iskefu == 1) {
					this.$router.push("ServiceOnline");
				} else {
					this.$toast.fail(this.$t("setting.forbid"));
				}
			},
			getUserInfo() {
				this.$http({
					method: 'get',
					url: 'user_info'
				}).then(res => {
					if (res.code === 200) {
						this.userInfo = res.data;
						console.log('12312312',this.userInfo);
						this.menu_top = 15;
						if (this.userInfo.status !== 1) {
							this.$toast(this.$t("video.account_out"));
							localStorage.clear()
							this.$router.push({
								path: '/Login'
							})
						}
					} else if (res.code === 401) {
						this.$toast(res.msg);
					}
				})
			},
		},
		created() {
			if (localStorage.getItem('token')) {
				this.getUserInfo();
			} else {
				this.userInfo.username = this.$t("setting.log_reg");
				this.userInfo.ip = this.$t("setting.more_service");
				this.userInfo.header_img = "img/mine/avatar.png";
			}
		}
	};
</script>

<style scoped>
	.page {
		position: absolute !important;
		top: 0;
		left: 0;
		right: 0;
		background-color: #f2f2f5;
	}

	.mine {
		position: relative;
		bottom: 10px;
		background: #f2f2f5;
	}

	.mine .wrapper {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}

	.nav-bar {
		background: #262626;
	}

	.mine .header {
		/* background: linear-gradient(90deg, #f487e0,#988fba); */
		/* background-image: 'https://luxurylu.cc/null.png'; */
		/* background: #262626; */
		/* background: url(https://luxurylu.cc/IMG_0164.JPG) no-repeat; */
    /* background-size: 100% 100%; */
		padding-bottom: 100px;
	}

	::v-deep .van-nav-bar__content {
		height: 100px;
	}

	::v-deep .van-hairline--bottom::after {
		border-bottom-width: 0px;
	}

	.mine .header .van-nav-bar .van-icon {
		font-size: 45px;
	}

	.mine .header .user-wrapper {
		display: flex;
		align-items: center;
		margin: 0px 40px 0px 40px;
		height: 130px;
	}

	.mine .user_img {
		height: 130px;
		width: 130px;
	}

	::v-deep .van-loading__spinner {
		height: 50px;
		width: 50px;
	}

	::v-deep .van-image__error-icon {
		font-size: 70px;
	}

	.mine .header .user-wrapper .login-content {
		flex: 1;
		margin-left: 30px;
	}

	.mine .header .user-wrapper .login-content .login-btn {
		display: inline-block;
		font-size: 40px;
		line-height: 0px;
		color: #fff;
	}

	.mine .header .user-wrapper .login-content .login-label {
		margin-top: -13px;
		font-size: 28px;
		color: hsla(0, 0%, 100%, .6);
	}

	.mine .page-bg {
		height: 500px;
		background: linear-gradient(90deg, #f487e0,#988fba);
	}

	.mine .content {
		position: relative;
		padding: 10px 30px 30px;
		min-height: 500px;
		background-color: #f2f2f5;
	}

	::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
		color: #ffffff;
		font-size: 35px;
	}

	.mine .wrapper .content .finance {
		position: absolute;
		display: flex;
		align-items: center;
		top: -55px;
		left: 30px;
		right: 30px;
		height: 120px;
		background-color: #fff;
		border-radius: 15px;
		box-shadow: 0 1.5px 1px 0 #e4e4e7;
	}

	.mine .wrapper .content .finance .line {
		width: 3px;
		height: 40px;
		background-color: #ccc;
	}

	.mine .wrapper .content .finance .finance-item {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	.mine .wrapper .content .finance .finance-item .text {
		margin-left: 30px;
		font-size: 30px;
		color: #000;
		font-weight: 500;
	}

	.mine .wrapper .content .finance .finance-item .icon {
		font-size: 50px;
	}

	.mine .wrapper .content .menu {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		background-color: #fff;
		border-radius: 15px;
		box-shadow: 0 1.5px 1px 0 #e4e4e7;
	}

	.mine .wrapper .content .menu .menu-item {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 50%;
		height: 130px;
	}

	.mine .wrapper .content .menu .menu-item .menu-item-label {
		font-size: 30px;
		color: #868686;
		font-weight: 500;
	}

	.mine .wrapper .content .menu .menu-item .menu-item-icon {
		margin: 25px;
		width: 60px;
		height: 60px;
		-o-object-fit: contain;
		object-fit: contain;
	}

	.mine .wrapper .content .wallet {
		margin-top: 80px;
		padding: 0 30px;
		background-color: #fff;
		border-radius: 15px;
		box-shadow: 0 1.5px 1px 0 #e4e4e7;
	}

	.mine .wrapper .content .wallet .part-1 {
		display: flex;
		align-items: center;
		height: 100px;
	}

	.mine .wrapper .content .wallet .font-primary-color {
		color: #000;
	}

	.font-gray {
		color: #868686;
	}

	.mine .wrapper .content .wallet .part-2 {
		display: flex;
		align-items: center;
		height: 150px;
	}

	.mine .wrapper .content .wallet .part-2 .balance {
		flex: 1;
		font-size: 60px;
		color: #f487e0;
		font-weight: 700;
	}

	.mine .wrapper .content .wallet .van-hairline--bottom::after {
		border-bottom-width: 3px;
	}

	.mine .wrapper .content .wallet .part-2 .refresh-btn {
		margin-left: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50px;
		height: 50px;
		font-size: 30px;
		border-radius: 50%;
		color: #ffffff;
		background-color: #e6c3a1;
	}
</style>